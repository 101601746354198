var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-detail-view',{attrs:{"loading":_vm.loading,"title":this.type === 'create'
      ? 'Создание сводного отчета по ВКК'
      : 'Сводный отчет по ВКК',"subTitle":_vm.SummaryReportStatus[_vm.dataSource.Status],"isUnsaved":_vm.unsaved,"fixedHeader":""},on:{"onSave":_vm.save,"onRefresh":_vm.refresh}},[_c('report-activator',{attrs:{"slot":"action","report":['ActionPlanReport', 'SummaryReport', 'FullSummaryReport'],"params":{
      objectId: { name: 'pSummaryReport', value: _vm.$route.params.objectId },
    }},slot:"action"}),(
      _vm.dataSource.DoneSteps &&
      _vm.dataSource.Status === 0 &&
      _vm.dataSource.DoneSteps.length >= 6 &&
      _vm.dataSource.IsAuthor
    )?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","dark":"","color":"primary"},on:{"click":function($event){return _vm.updateStatus(1)}},slot:"action"},[_vm._v(" Передать на подпись ")]):_vm._e(),(_vm.dataSource.Status === 1 && _vm.dataSource.IsApprovement)?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","dark":"","color":"primary"},on:{"click":function($event){return _vm.sign()}},slot:"action"},[_vm._v(" Подписать и опубликовать ")]):_vm._e(),(
      _vm.dataSource.Status === 1 &&
      (_vm.dataSource.IsAuthor || _vm.dataSource.IsApprovement)
    )?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","dark":"","color":"primary"},on:{"click":function($event){return _vm.updateStatus(0)}},slot:"action"},[_vm._v(" Вернуть на доработку ")]):_vm._e(),(false && _vm.dataSource.Status === 2 && _vm.dataSource.IsApprovement)?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","dark":"","color":"primary"},on:{"click":function($event){return _vm.updateStatus(0)}},slot:"action"},[_vm._v(" Вернуть на доработку ")]):_vm._e(),_c('div',{staticClass:"d-flex summary-report-edit"},[(_vm.dataSource.Step)?_c('div',{staticClass:"summary-report-edit__nav"},[_c('v-stepper',{staticStyle:{"position":"sticky","top":"98px"},attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(1),"step":"1","editable":"","edit-icon":'$complete'}},[_vm._v(" Начало ")]),_c('v-stepper-content',{attrs:{"step":"1"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(2),"step":"2","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Проверки ")]),_c('v-stepper-content',{attrs:{"step":"2"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(3),"step":"3","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Нежелательные события ")]),_c('v-stepper-content',{attrs:{"step":"3"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(4),"step":"4","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Статистические показатели ")]),_c('v-stepper-content',{attrs:{"step":"4"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(5),"step":"5","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Мониторинги ")]),_c('v-stepper-content',{attrs:{"step":"5"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(6),"step":"6","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Выводы ")]),_c('v-stepper-content',{attrs:{"step":"6"}}),_c('v-stepper-step',{attrs:{"complete":_vm.dataSource &&
            _vm.dataSource.DoneSteps &&
            _vm.dataSource.DoneSteps.includes(7),"step":"7","editable":_vm.dataSource.Id > 0,"edit-icon":'$complete'}},[_vm._v(" Сводная таблица ")]),_c('v-stepper-content',{attrs:{"step":"7"}})],1)],1):_vm._e(),_c('div',{staticClass:"summary-report-edit__content"},[(_vm.dataSource.Step)?_c('consolidated-report-steps',{ref:"steps",attrs:{"step":_vm.step,"loading":_vm.loading,"isUnsaved":_vm.unsaved,"data":_vm.dataSource,"readonly":_vm.readonly},on:{"update:step":function($event){_vm.step=$event},"update:loading":function($event){_vm.loading=$event},"update:isUnsaved":function($event){_vm.unsaved=$event},"update:is-unsaved":function($event){_vm.unsaved=$event}}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }